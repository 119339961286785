.breadcrumb {
    margin: 20px 0 25px;
    position: relative;
    left: calc(50% + -155px);
    max-width: 635px;

    ul  {
        display: flex;
        flex-wrap: wrap;
        gap: 0px 30px;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-dark;
        position: relative;

        &:not(:last-child) {
            &:before {
                position: absolute;
                content: "";
                mask-image: url(/wp-content/themes/dourdan/assets/src/images/sprite-images/Pictos/ic_nav_droite.svg);
                background-color: $color-dark;
                mask-repeat: no-repeat;
                top: unset;
                right: -25px;
                width: 22px;
                height: 22px;
                -webkit-mask-size: 22px;
                mask-size: 22px;
            }
        }

        &:first-child {
            padding-left: 0;
        }

        a {
            font-family: $font-family;
            font-weight: $font-weight-normal;
            font-size: 16px;
            line-height: 22px;
            
            &:hover {
                color: $color-dark;
                text-decoration: underline;
            }
        }

        span br {
            display: none;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .breadcrumb {
        left: calc(50% - 215px);
        max-width: 520px;
    }

}

// 900
@media screen and (max-width: $medium) {

    .breadcrumb {
        left: calc(50% - 310px);
        max-width: 620px;
    }

}

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        left: calc(50% - 150px);
        max-width: 300px;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 40px;
            width: 100%;
            height: 1px;
        }
    }

}
