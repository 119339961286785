#nav-main {
    z-index: 10;
    margin-left: 25px;
    margin-bottom: -25px;

    .container--nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        transition: $duration ease-out;
    } 

    .nav-main-item {

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        &:last-child {
           .nav-main-link {
                &:after {
                    width: 0;
                    height: 0;
                    background: transparent;
                }
            }
        }

        &:hover {
            .nav-main-link:before {
                opacity: 1;
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: flex-start;
            height: 60px;
            padding: 0 35px;
            color: $color-dark;
            text-transform: uppercase;
            font-family: $font-family;
            font-weight: $font-weight-normal;
            font-size: 22px;
            line-height: 24px;
            
            &:before {
                position: absolute;
                content: "";
                width: 90px;
                height: 90px;
                top: 35px;
                left: 50%;
                border-radius: 50px;
                background-color: $color-third;
                transform: translateX(-50%);
                opacity: 0;
                z-index: -2;
            }

            &:after {
                content: '';
                position: absolute;
                top: 7px;
                right: 0;
                width: 1px;
                height: 10px;
                background: $color-dark;
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            top: 140px;
            left: 0;
            width: 100%;
            padding: 30px 15px;
            background: $color-second;

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                margin: -1px; // Fix space beetween inline-flex
                padding: 0;
                margin-bottom: 10px;
                line-height: initial;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 60px;
                    padding: 15px 10px;
                    margin-bottom: 1px; // Fix space beetween inline-flex
                    border: 1px solid rgba(255, 255, 255, 0.3);
                    border-radius: 4px;
                    color: $color-white;
                    font-family: $font-family;
                    font-weight: $font-weight-bold;
                    font-size: 16px;
                    line-height: 22px;

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        position: absolute;
                        right: 2px;
                        color: rgba(255, 255, 255, 0.3);;
                        transition: all $duration;
                    }

                    &:hover {
                        background-color: $color-white;
                        color: $color-second;

                        &::after {
                            color: $color-second;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    display: flex;
                    padding: 17px 10px 17px 15px;
                    border-bottom: 0;
                    font-size: $font-size--text-small;
                    font-weight: $font-weight;
                    text-transform: initial;
                    line-height: initial;

                    &::before {
                        display: none;
                    }
                } 
            }
        }
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    #nav-main {
        margin-left: 15px;

        .nav-main-item .nav-main-link {
            padding: 0 15px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    
    #nav-main {
        display: none;
    }
}


// 640
@media screen and (max-width: $small) {

}
