.header {
    position: relative;

    &--fixed {
        .header-top {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            padding: 0;
            z-index: 20;
            height: 90px;
            box-shadow: 0 0 1px rgba(27,35,54,.08),1px 1px 10px rgba(27,35,54,.08);
            transition: all 0.5s ease-in-out;

            &__container {
                align-items: center;
                padding-bottom: 0;
            }

            &__demarches {
                overflow: hidden;
                height: 90px;
                padding-bottom: 10px;

                span {
                    padding-top: 0;
                }
            }

            &__title {
                width: fit-content;
                height: fit-content;

                svg {
                    width: 90px;
                    height: 64px;
                }
            }

            #nav-main {
                margin-bottom: -40px;
                margin-left: 38px;

                .nav-main-item {
                    .sub-menu {
                        top: 90px;
                    }

                    .nav-main-link {
                        padding: 0 40px;
                    }
                }
            }

            .tools {
                margin-bottom: -7px;
                margin-left: 7px;
            }
        }
    }

}

.header-top {
    height: 140px;
    background-color: $color-white;
    transition: all 0.5s ease-in-out;

    &__container {
        display: flex;
        align-items: flex-end;
        height: 100%;
        padding-bottom: 20px;
        align-items: flex-end;
    }

    &__logo {
        display: flex;
    }

    &__title {
        width: 140px;
        height: 100px;
        margin: 0;
        font-size: 0;
        line-height: 0;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0;
        align-items: center;
    }

    &__demarches {
        color: $color-dark;
        font-family: $font-family;
        font-weight: $font-weight-bold;
        font-size: 26px;
        line-height: 24px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        text-transform: uppercase;
        width: 220px;
        height: 120px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: visible;
        margin: 0 0 0 35px;
        z-index: 2;
        transition: all 0.5s ease-in-out;

        &:before {
            position: absolute;
            content: "";
            mask-image: url(/wp-content/themes/dourdan/assets/src/images/sprite-images/home/bulle_demarches.svg);
            top: -70px;
            left: 0;
            width: 220px;
            height: 210px;
            mask-repeat: no-repeat;
            background-color: $color-main;
            z-index: -1;
            transition: all 0.5s ease-in-out;
        }

        span {
            color: $color-white;
            font-family: 'Kaushan Script', cursive;
            font-weight: $font-weight-normal;
            font-size: 35px;
            line-height: 30px;
            letter-spacing: -0.075em;
            text-transform: lowercase;
            padding-top: 13px;
            transition: all 0.5s ease-in-out;
        }

        &:hover {
            color: $color-white;
            
            &:before {
                background-color: $color-second;
            }
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

body:not(.home) {
    .tools {
        &__content {
            & > svg {
                fill: $color-bg--neutral;
            }
        }
        
        &::before {
            background: $color-bg--neutral;
        }
    }
}

.tools {
    margin-left: 2px;
    margin-bottom: 4px;
    
    &__content {
        display: flex;
        justify-content: space-between;
        gap: 15px;
    }

    .tool {
        margin: 0;
        padding: 0;
        width: 40px;
        height: 40px;
        background: none;
        border: none;
        min-height: 40px;

        svg {
            fill: $color-dark;
            width: 100%;
            height: 100%;
            background-color: $color-light;
            border-radius: 50px;
            transition: all $duration ease-in-out;
        }

        &:hover {
            svg {
                background-color: $color-third;
            }
        }
    }

    &__search {
        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
    }
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header--fixed {
        .header-top {
            #nav-main {
                margin-left: 10px;

                .nav-main-item .nav-main-link {
                    padding: 0px 15px;
                }
            }

            .tools {
                margin-left: 25px;
            }

            &__demarches {
                margin-left: 30px;
            }
        }
    }

    .header-top {
        &__title {
            width: 130px;
            height: 93px;
            margin-bottom: 7px;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &__demarches {
            margin-left: 10px;
            margin-right: -30px;
        }

        .tools {
            margin-left: 5px;

            &__content {
                gap: 10px;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .header-top {
        position: relative;
        z-index: 15;

        &__content {
            margin-left: 20px;
        }

        &__demarches {
            margin-bottom: -20px;
            margin-left: 5px;
        }
        
        .tools {
            margin-left: 20px;
        }
    }

    .privacy {
        &__container {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }

        &__button {
            margin: 0;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .header-top {
        &__title {
            width: 140px;
            height: 100px;
            margin-bottom: 0;
        }

        &__container {
            justify-content: space-between;
        }

        &__content {
            flex-direction: column-reverse;
            gap: 25px;
        }

        &__demarches {
            display: none;
        }

        .tools {
            margin-left: 0;
        }
    }
}
