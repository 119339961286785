.front-page {
    position: relative;
    overflow: hidden;
    z-index: 0;

    &__title {
        font-family: 'Kaushan Script', cursive;
        font-weight: $font-weight-normal;
        font-size: 50px;
        line-height: 55px;
        letter-spacing: -0.075em;
        color: $color-second;
        margin: 0;
        padding: 0;
        margin-left: 100px;

        &:before {
            display: none;
        }
    }

    &__description {
        font-family: $font-family;
        font-weight: $font-weight-bold;
        font-size: 50px;
        line-height: 55px;
        color: $color-dark;
        display: flex;
        gap: 20px;
        margin-top: -10px;

        svg {
            margin-top: 25px;
        }
    }

    &__button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        min-height: 50px;
        background-color: $color-second;
        color: $color-white;
        text-transform: uppercase;
        font-family: $font-family;
        font-weight: $font-weight-normal;
        font-size: 16px;
        line-height: 18px;
        border-radius: 30px;
        transition: all $duration ease-in-out;
    }

    &__zones {
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 400px;
            bottom: 30px;
            left: 0;
            background-color: $color-light;
        }

        &--container {
            display: flex;
            gap: 60px;
        }
    }
}


//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    > .container {
        position: relative;
    }

    .swiper-container {
        overflow: initial;
    }

    .swiper-slide:not(.swiper-slide-active) {
        opacity: 0;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--navigation {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
        }
    }

    .slideshow {
        height: 475px;
        width: calc(50vw + 230px);
        position: absolute;
        left: 0;

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: absolute;
            left: calc(50% + -220px);
            top: 475px;
            width: 720px;
            height: auto;
            padding: 15px 15px 0;
            border-left: 10px solid $color-third;
            color: $color-white;
        }

        &__slide {
            width: 100%;
        }

        &__title {
            font-family: $font-family;
            font-weight: $font-weight-bold;
            font-size: 28px;
            line-height: 30px;
            color: $color-dark;
        }

        &__description {
            font-family: $font-family;
            font-weight: $font-weight-normal;
            font-size: 16px;
            line-height: 22px;
            color: $color-dark;
        }

        &--video {
            img {
                display: none;
            }
        }

        // Navigation (arrow)
        &__navigation {
            position: absolute;
            left: calc(50% - 190px);
            top: 0;
            height: 100%;
            pointer-events: none;
    
            &__btn {
                pointer-events: auto;
                width: 30px;
                height: 30px;
                margin: 0;
                padding: 0;
                background: $color-third;
                border: 0;
                border-radius: 50px;
                bottom: 25px;
                top: unset;
                right: unset;
                left: unset;
                right: unset;
                min-height: unset;

                &::before {
                    position: absolute;
                    content: "";
                    top: -5px;
                    left: -5px;
                    width: 40px;
                    height: 40px;
                    border-radius: 50px;
                    border: 1px solid $color-third;
                    background-color: transparent;
                    transition: all $duration ease;
                }
    
                &:hover {
                    &:before {
                        width: 45px;
                        height: 45px;
                        top: -7.5px;
                        left: -7.5px;
                    }
                }

                &--prev, &--next {

                    &::after {
                        position: relative;
                        content: "";
                        mask-image: url(/wp-content/themes/dourdan/assets/src/images/sprite-images/Pictos/ic_nav_gauche.svg);
                        top: 0;
                        width: 30px;
                        height: 30px;
                        mask-repeat: no-repeat;
                        mask-size: 30px;
                        background-color: $color-dark;
                        transition: all $duration ease;
                    }
                }

                &--prev {

                    &::after {
                        mask-image: url(/wp-content/themes/dourdan/assets/src/images/sprite-images/Pictos/ic_nav_gauche.svg);
                    }
                }

                &--next {
                    margin-left: 50px;

                    &::after {
                        mask-image: url(/wp-content/themes/dourdan/assets/src/images/sprite-images/Pictos/ic_nav_droite.svg);
    
                    }
                }
            }
        }
    }
}

//======================================================================================================
// Accès rapides
//======================================================================================================

.access {
    margin-top: 40px;

    &__container {
        display: flex;
        justify-content: flex-end;
    }

    &__list {
        width: 345px;
        margin-right: 55px;
        padding: 55px 0 75px 85px;
        border-left: 10px solid $color-main;
        display: flex;
        flex-wrap: wrap;
        gap: 25px 50px;
        position: relative;
        z-index: 5;

        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 90px;
            height: 10px;
            background-color: $color-main;
        }

        &:after {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            width: 220px;
            height: 10px;
            background-color: $color-main;
        }
    }

    &__link {
        width: 100px;
        font-family: $font-family;
        font-weight: $font-weight-normal;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-transform: uppercase;
        gap: 7px;
        position: relative;
        transition: all $duration ease-in-out;

        &:before {
            position: absolute;
            content: " ";
            width: 50px;
            height: 50px;
            top: 15px;
            left: 23px;
            background-color: $color-light;
            border-radius: 50px;
            z-index: -1;
        }

        span {
            text-align: center;
            height: 36px;
            display: flex;
            align-items: flex-end;
        }

        > div svg {
            margin-left: 30px;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            color: $color-second;

            > div svg {
                margin-left: 15px;
            }
        }
    }
}

//======================================================================================================
// Agenda
//======================================================================================================

.home-events {
    margin-top: 100px;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 445px;
        bottom: 0;
        left: 0;
        background-color: $color-light;
    }

    &__container {
        position: relative;
    }

    &__header {
        > div {
            display: flex;
            align-items: center;
            gap: 30px;
        }
    }

    &__navigation {
        height: 55px;
        display: flex;
        align-items: center;

        &__prev,
        &__next {
            top: unset;
            right: unset;
            left: unset;
            bottom: unset;
            margin-top: unset;
            align-items: unset;
            justify-content: unset;
            width: 30px;
            height: 30px;
            margin: 0;
            padding: 0;
            background-color: $color-second;
            border: 0;
            border-radius: 50px;
            transition: all $duration ease;

            &::before {
                position: absolute;
                content: "";
                top: -5px;
                width: 40px;
                height: 40px;
                left: -5px;
                border-radius: 50px;
                border: 1px solid $color-second;
                background-color: transparent;
                transition: all $duration ease;
            }

            &::after {
                position: relative;
                content: "";
                top: 0;
                width: 30px;
                height: 30px;
                mask-repeat: no-repeat;
                mask-size: 30px;
                background-color: $color-white;
                transition: all $duration ease;
            }

            &:hover {
                &:before {
                    width: 45px;
                    height: 45px;
                    top: -7.5px;
                    left: -7.5px;
                }
            }
        }

        &__prev {
            
            &::after {
                mask-image: url(/wp-content/themes/dourdan/assets/src/images/sprite-images/Pictos/ic_nav_gauche.svg);
            }
        }

        &__next {
            margin-left: 55px;
            &::after {
                mask-image: url(/wp-content/themes/dourdan/assets/src/images/sprite-images/Pictos/ic_nav_droite.svg);
            }
        }
    }

    &__swiper {
        margin-top: 65px;
    }

    &__list {
        margin-bottom: 60px;
    }

    &__link {
        width: 370px;
        height: 535px;
        position: relative;
        display: inline-block;

        &:hover, &:focus {
            .home-events__content {
                margin-left: 15px;
            }

            .home-events__title--small {
                color: $color-second;
            }

            .home-events__date p:first-child:before {
                background-color: $color-third;
            }
        }
    }

    &__image {
        position: absolute;
        top: 0;
        right: 0;
        width: 305px;
        height: 425px;
        overflow: hidden;
        z-index: 0;
        box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);

        &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            background: linear-gradient(180deg, rgba(22, 65, 148, 0) 34.9%, #164194 100%);
            z-index: 2;
        }

        img {
            width: 100%;
            height: 100%;
            transform: scale(1);
            transition: all $duration ease;
            object-fit: cover;
        }
    }

    .notimg {
        background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-light;
        background-position: center;
        width: 305px;
        height: 425px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
        box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 280px;
        height: 265px;
        z-index: 2;
        transition: all $duration ease-in-out;

        &:before {
            position: absolute;
            content: "";
            width: 300px;
            height: 285px;
            bottom: -9px;
            left: -7px;
            background-image: url(/wp-content/themes/dourdan/assets/src/images/sprite-images/home/bulle_agenda.svg);
            background-repeat: no-repeat;
            z-index: -1;
        }
    }

    &__date {
        display: flex;
        gap: 10px;
        padding-top: 40px;
        margin-left: 30px;
        color: $color-dark;

        p {
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: all $duration ease-in-out;
            
            &:first-child {
                position: relative;

                &:before {
                    position: absolute;
                    content: " ";
                    width: 60px;
                    height: 60px;
                    top: 2px;
                    left: -25px;
                    background-color: $color-light;
                    border-radius: 50px;
                    z-index: -1;
                    transition: all $duration ease-in-out;
                }
            }
        }

        &__day {
            font-family: $font-family;
            font-weight: $font-weight-black;
            font-size: 45px;
            line-height: 50px;
        }

        &__month {
            font-family: $font-family;
            font-weight: $font-weight-light;
            font-size: 25px;
            line-height: 31px;
            text-transform: uppercase;
            margin-top: -5px;
        }

        span svg {
            margin-top: 22px;
        }
    }

    &__infos {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        height: 100px;
        justify-content: center;
        margin-top: 10px;
    }

    &__title--small {
        font-family: $font-family;
        font-weight: $font-weight-bold;
        font-size: 28px;
        line-height: 30px;
        color: $color-dark;
        text-align: center;
        max-width: 230px;
        margin: 0;
        padding: 0;
        transition: all $duration ease-in-out;
    }

    &__location {
        font-family: $font-family;
        font-weight: $font-weight-normal;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        max-width: 160px;
        color: $color-dark;
    }

    &__button {
        top: 60px;
        right: 15px;

        &:hover, &:focus {
            color: $color-dark;
            background-color: $color-third;
        }
    }
}

//======================================================================================================
// Actus
//======================================================================================================

.news {
    margin-top: 100px;

    &__container {
        overflow: hidden;
        position: relative;
    }

    &__title {
        color: $color-third;
        margin-left: 135px;
    }

    &__description {
        svg {
            path {
                fill: $color-third;
            }
        }
    }

    &__header {
        > div {
            display: flex;
            align-items: center;
            gap: 25px;
        }
    }

    &__navigation {
        height: 55px;
        display: flex;
        align-items: center;

        &__prev,
        &__next {
            top: unset;
            right: unset;
            left: unset;
            bottom: unset;
            margin-top: unset;
            align-items: unset;
            justify-content: unset;
            width: 30px;
            height: 30px;
            margin: 0;
            padding: 0;
            background-color: $color-third;
            border: 0;
            border-radius: 50px;
            transition: all $duration ease;

            &::before {
                position: absolute;
                content: "";
                top: -5px;
                width: 40px;
                height: 40px;
                left: -5px;
                border-radius: 50px;
                border: 1px solid $color-third;
                background-color: transparent;
                transition: all $duration ease;
            }

            &::after {
                position: relative;
                content: "";
                top: 0;
                width: 30px;
                height: 30px;
                mask-repeat: no-repeat;
                mask-size: 30px;
                background-color: $color-dark;
                transition: all $duration ease;
            }

            &:hover {
                &:before {
                    width: 45px;
                    height: 45px;
                    top: -7.5px;
                    left: -7.5px;
                }
            }
        }

        &__prev {
            
            &::after {
                mask-image: url(/wp-content/themes/dourdan/assets/src/images/sprite-images/Pictos/ic_nav_gauche.svg);
            }
        }

        &__next {
            margin-left: 55px;
            &::after {
                mask-image: url(/wp-content/themes/dourdan/assets/src/images/sprite-images/Pictos/ic_nav_droite.svg);
            }
        }
    }

    &__swiper {
        overflow: initial;
        padding-bottom: 60px;
    }

    &__list {
        margin-top: 60px;
    }

    &__link {
        position: relative;
        width: 370px;
        height: 370px;
        display: flex;

        &:hover, &:focus {
            .news__content {
                height: 160px;
            }

            .news__introduction {
                transform: translateY(0); 
                opacity: 1;

            }
        }
    }

    &__image {
        position: relative;
        overflow: hidden;
        width: 370px;
        height: 370px;
        
        img {
            transform: scale(1);
            transition: all $duration ease;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-light;
            background-position: center;
            width: 370px;
            height: 370px;
        }
    }

    &__content {
        position: absolute;
        bottom: -55px;
        padding: 30px 25px;
        box-shadow: 0 0 1px rgba(27,35,54,.08),1px 1px 10px rgba(27,35,54,.08);
        border-radius: 20px;
        background-color: hsla(0,0%,100%,.95);
        transition: all $duration ease-in-out;
        width: calc(100% - 80px);
        left: 50%;
        transform: translateX(-50%);
        height: 60px;
        overflow: hidden;
    }

    &__title--small {
        color: $color-dark;
        font-family: $font-family;
        font-weight: $font-weight-bold;    
        font-size: 28px;
        line-height: 30px;
        margin: 0;
        padding: 0;
    }

    &__introduction {
        color: $color-dark;
        font-family: $font-family;
        font-weight: $font-weight-normal;
        font-size: 16px;
        line-height: 22px;
        margin-top: 10px;
        opacity: 0;
        transition: all $duration ease-in-out;
    }

    &__button {
        right: 15px;
        top: 60px;
        background-color: $color-third;
        color: $color-dark;

        &:hover, &:focus {
            background-color: $color-second;
            color: $color-white;
        }
    }
}

//======================================================================================================
// Social Wall
//======================================================================================================

.social-wall {
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 400px;
        bottom: 0;
        left: 0;
        background-color: $color-light;
    }

    &__container {
        padding: 130px 15px 40px;
        position: relative;

        &:before, &:after {
            position: absolute;
            content: "";
            background-color: $color-main;
            top: 208px;
            right: 50px;
        }

        &:before {
            width: 90px;
            height: 10px;
        }

        &:after {
            width: 10px;
            height: 230px;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        &__left > svg {
            margin-left: 130px;
        }

        &__right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 25px;
            margin-right: 160px;

            p {
                font-family: $font-family;
                font-weight: $font-weight-normal;
                font-size: 16px;
                line-height: 18px;
                color: $color-dark;
                text-transform: uppercase;
            }
        }
    }

    &__description {
        svg {
            path {
                fill: $color-main;
            }
        }
    }

    &__pictos {
        display: flex;
        gap: 10px;

        a {
            position: relative;
            width: 40px;
            height: 40px;

            &:before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: transparent;
                border: 1px solid $color-main;
                border-radius: 50px;
                transition: all $duration ease;
            }

            svg {
                position: absolute;
                background-color: $color-main;
                border-radius: 50%;
                left: 5px;
                top: 5px;
                width: 30px;
                height: 30px;
            }

            &:hover, &:focus {
                &:before {
                    width: 45px;
                    height: 45px;
                    top: -3px;
                    left: -3px;
                }
            }
        }
    }

    &__wrapper {
        margin-top: 60px;
    }

    #ff-stream-1 {
        background-color: transparent;
        padding: 0;
        margin: 0;
        height: 460px;
        min-height: unset;

        .ff-header, .ff-item-bar, .ff-loadmore-wrapper {
            display: none;
        }

        .ff-stream-wrapper {
            margin: 0 -30px;
        }

        .ff-item-cont {
            height: 460px;
        }

        .ff-item {
            box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);
            background-color: $color-white;

            .ff-img-holder {
                height: 215px;
            }
        }

        .ff-content {
            font-size: 16px;
            max-height: 75%;
        }

        .ff-item-meta {
            margin: 11% 7% 7%;

            h6 {
                color: $color-dark!important;
            }
        }

        .ff-nickname, .ff-timestamp {
            color: $color-dark!important;
        }

        .ff-slideshow-media {
            width: 75vw;
            margin: 3% auto;
        }
    }
}

//======================================================================================================
// Documents
//======================================================================================================

.home-documents {
    margin-top: 85px;

    &__container {
        position: relative;
    }

    &__title {
        color: $color-third;
        margin-left: 122px;
    }

    &__description {
        svg path {
            fill: $color-third;
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
    }

    &__item {
        width: 370px;
        height: 375px;
        display: flex;
        align-items: flex-end;
        position: relative;
    }

    &__image {
        width: 270px;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
        }

        &--notimg {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-bg--image;
        
            svg {
              width: 40%;
            }
        }
    }

    &__content {
        position: absolute;
        right: 0;
        bottom: 35px;
        width: 210px;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 2;

        &:before {
            position: absolute;
            content: "";
            width: 245px;
            height: 230px;
            bottom: -12px;
            left: -17px;
            background-size: 245px;
            background-image: url(/wp-content/themes/dourdan/assets/src/images/sprite-images/home/bulle_kiosque.svg);
            background-repeat: no-repeat;
            z-index: -1;
        }
    }

    &__infos {
        display: flex;
        gap: 3px;
        
        p {
            font-family: $font-family;
            font-weight: $font-weight-normal;
            font-size: 14px;
            line-height: 20px;
            color: $color-dark;
        }
    }

    &__title--small {
        width: 165px;
        text-align: center;
        padding: 0;
        margin: 5px 0 10px;
        font-family: $font-family;
        font-weight: $font-weight-bold;
        font-size: 22px;
        line-height: 24px;
        color: $color-dark;
    }

    &__buttons {
        display: flex;
        gap: 13px;
    }

    &__btn {
        margin: 0;
        padding: 0;
        position: relative;
        width: 40px;
        height: 40px;

        &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: transparent;
            border: 1px solid $color-third;
            border-radius: 50px;
            transition: all $duration ease;
        }

        svg {
            position: absolute;
            background-color: $color-third;
            border-radius: 50%;
            left: 5px;
            top: 5px;
            width: 30px;
            height: 30px;
        }

        &:hover, &:focus {
            &:before {
                width: 45px;
                height: 45px;
                top: -3px;
                left: -3px;
            }
        }
    }

    &__button {
        top: 50px;
        right: 15px;
        background-color: $color-third;
        color: $color-dark;

        &:hover, &:focus {
            background-color: $color-second;
            color: $color-white;
        }
    }
}

//======================================================================================================
// Newsletter
//======================================================================================================

.home-newsletter {
    margin: 100px 0 120px;

    &__container {
        position: relative;

        &:before, &:after {
            position: absolute;
            content: "";
            height: 10px;
            left: 15px;
            background-color: $color-main
        }

        &:before {
            width: 45px;
            top: 0;
        }

        &:after {
            width: 85px;
            bottom: 0;
        }
    }

    &__wrapper {
        position: relative;
        border-left: 10px solid $color-main;
        border-right: 10px solid $color-main;
        min-height: 100px;
        display: flex;
        align-items: center;

        &:before, &:after {
            position: absolute;
            content: "";
            height: 10px;
            right: 0;
            background-color: $color-main
        }

        &:before {
            width: 100px;
            top: 0;
        }

        &:after {
            width: 45px;
            bottom: 0;
        }

        > p {
            margin-left: 50px;
            font-family: $font-family;
            font-weight: $font-weight-normal;
            font-size: 16px;
            line-height: 22px;
        }

        > a {
            margin-left: 90px;
            font-family: $font-family;
            font-weight: $font-weight-normal;
            font-size: 16px;
            line-height: 18px;
            color: $color-dark;
            text-transform: uppercase;
            position: relative;
            transition: all $duration ease-in-out;

            &:before {
                position: absolute;
                content: " ";
                width: 50px;
                height: 50px;
                top: -15px;
                left: -25px;
                background-color: $color-light;
                border-radius: 50px;
                z-index: -1;
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus {
                color: $color-dark;

                &:before {
                    left: -15px;
                    background-color: $color-third;
                }
            }
        }
    }

    &__title {
        font-size: 35px;
        color: $color-main;
        margin-left: 50px;
    }

    &__description {
        font-size: 40px;
        margin-left: 85px;
        margin-top: -25px;
    }
}

//======================================================================================================
// Zoom Sur
//======================================================================================================

.zoom {
    margin-left: -105px;

    &__wrapper {
        position: relative;

        svg:nth-child(2) {
            position: absolute;
            left: 0;
        }
    }

    &__bloc {
        &:hover, &:focus {
            .zoom__header {
                margin-left: 0;

                svg path {
                    fill: $color-third;
                }
            }

            .zoom__title {
                color: $color-third;
            }
        }
    }

    &__content {
        position: absolute;
        bottom: 90px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__header {
        display: flex;
        align-items: flex-end;
        margin-left: -35px;
        transition: all $duration ease-in-out;

        svg {
            margin: 0 10px 7px 0;

            path {
                fill: $color-main;
                transition: all $duration ease-in-out;
            }
        }
    }

    &__title {
        color: $color-main;
        margin: 0;
        transition: all $duration ease-in-out;
    }

    &__description {
        color: $color-white;
        font-size: 34px;
        line-height: 43px;
        width: 400px;
        text-align: center;
    }
}

//======================================================================================================
// Retour en images
//======================================================================================================

.a-voir {
    margin-top: -15px;

    &__header {
        margin-left: -90px;
    }

    &__title {
        margin-left: 123px;
        color: $color-main;
    }

    &__description svg path {
        fill: $color-main;
    }

    &__blocs {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
    }

    &__bloc {
        &:nth-child(2), &:nth-child(3) {
            .a-voir__image {
                width: 230px;
                height: 195px;

                img {
                    width: 100%;
                    height: 100%;
                }

                &:before {
                    width: 230px;
                    height: 195px;
                }
            }
        }

        a {
            display: block;
            max-height: 195px;
        }
    }

    &__image {
        position: relative;
        width: 315px;
        height: 195px;

        figcaption {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            width: 190px;
            font-family: $font-family;
            font-weight: $font-weight-bold;
            font-size: 16px;
            line-height: 20px;
            color: $color-white;
            text-align: center;
            opacity: 0;
            transition: all $duration ease-in-out;
        }

        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 315px;
            height: 195px;
            border-radius: 20px;
            background: linear-gradient(180deg, rgba(22, 65, 148, 0) 0%, rgba(22, 65, 148, 0.81) 61.46%, #164194 100%);
            opacity: 0;
            transition: all $duration ease-in-out;
        }

        img {
            border-radius: 20px;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &:hover, &:focus {
            figcaption {
                opacity: 1;
            }

            &:before {
                opacity: 1;
            }
        }
    }
}

//======================================================================================================
// Sticky Réseaux Sociaux
//======================================================================================================

.social {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 10;
    top: 50%;
    right: 0;
    transform: translate(180px, -50%);
      
    a {
        display: flex;
        align-items: center;
        width : auto;
        background-color: $color-second;
        color: $color-white;
        padding: 15px 15px 15px 35px;
        height: 40px;
        border-radius: 20px 0px 0px 20px;
        color: $color-white;
        font-family: $font-family;
        font-weight: $font-weight-normal;
        font-size: 16px;
        line-height: 22px;
        padding: 0 5px 0 10px;
        transition: all 0.5s ease;

        &:not(:first-child) {
            margin-top: 15px;
        }

        svg {
            width: 30px;
            height: 30px;
            fill: $color-white;
            border-radius: 100%;
            margin-right: 5px;
        }

        &:hover, &:focus {
            transform: translateX(-180px);
        }

    }
}

//======================================================================================================
// AdminAreas
//======================================================================================================
.admin-blocs {
    .bloc {
        &__wrapper {
            &--video {
                cursor: pointer;
                position: relative;

                &::before {
                    position: absolute;
                    content: "";
                    background-image: url(/wp-content/themes/dourdan/assets/src/images/sprite-images/Pictos/ic_play.svg);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 40px;
                    height: 40px;
                    background-repeat: no-repeat;
                    background-size: 40px;
                    z-index: 2;
                    transition: all $duration ease;
                }
            }

            &:hover, &:focus {
                &--video {
                    &:before {
                        
                    }
                }
            }
        }

        .popup-area {
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 20;
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            background: $color-black--rgba;

            .container-popup-area iframe {
                max-width: 1000px;
                padding: 0 15px;
                margin: 0 auto;
                max-height: 650px;
            }

            &.show-popup {
                visibility: visible;
                opacity: 1;
            }

            .accordion {
                height: 0;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }

            .close-area-popup {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                position: absolute;
                top: 120px;
                right: calc((100% - 1170px)/2);
                width: 40px;
                height: 40px;
                background: $color-white;
                border-radius: 50px;

                &::before {
                    content: '\f335';
                    font: normal 30px/1 'dashicons';
                }
            }
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .front-page {
        &__description {
            gap: 15px;
            
            svg {
                width: 80px;
                height: 10px;
            }
        }

        &__title {
            margin-left: 30px;
        }

        &__zones {
            &:before {
                height: 315px;
            }

            &--container {
                gap: 30px;
            }
        }
    }
    
    .home-slideshow {
        .slideshow {
            width: calc(50vw + 160px);

            &__content {
                width: 535px;
                left: calc(50% - 250px);
            }

            &__navigation {
                left: calc(50% - 220px);
            }
        }
    }

    .access {
        &__list {
            margin-right: 0;
            padding: 65px 10px 70px 75px;
        }
    }

    .home-events {
        
        &__link {
            width: 300px;
        height: 520px;
        }

        &__image {
            width: 255px;
            height: 355px;
        }
    
        .notimg {
            width: 255px;
            height: 355px;
        }

        &__button {
            right: 10px;
        }
    }

    .news {
        margin-top: 85px;

        &__title {
            margin-left: 58px; 
        }

        &__link {
            width: 300px;
            height: 300px;

            &:hover, &:focus {
                .news__content {
                    height: 210px;
                }
            }
        }

        &__content {
            padding: 20px;
            width: calc(100% - 70px);
            bottom: -45px;
            height: 90px;
        }


        &__image {
            width: 300px;
            height: 300px;
    
            &--notimg {
                width: 300px;
                height: 300px;
            }
        }

        &__button {
            right: 10px;
        }
    }

    .social-wall {
        &__container {
            padding: 125px 15px 40px;

            &:before, &:after {
                right: 30px;
                top: 203px;
            }
        }

        &__header {
            &__left > svg {
                margin-left: 55px;
            }

            &__right {
                margin-right: 130px;

                p {
                    display: none;
                }
            }
        }

        &__wrapper {
            padding: 0 5px;
        }

        #ff-stream-1 .ff-item-meta {
            margin: 7%;
        }
    }

    .home-documents {
        &__title {
            margin-left: 43px;
        }

        &__item {
            width: 300px;
            height: 300px;
        }

        &__image {
            width: 215px;
        }

        &__content {
            bottom: 20px;
        }

        &__button {
            right: 10px;
        }
    }

    .home-newsletter {
        &__title {
            margin-left: 52px;
        }

        &__wrapper {
            > p {
                margin-left: 75px;
                max-width: 320px;
            }
            
            > a {
                margin-left: 80px;
            }
        }
    }

    .zoom {
        margin-left: -70px;

        &__wrapper {
            > svg {
                width: 528px;
                height: 508px;
            }
        }

        &__header {
            margin-left: 15px;
            gap: 10px;
            
            svg {
                width: 80px;
                height: 10px;
                margin-bottom: 10px;
                margin-right: 0;
            }
        }

        &__content {
            gap: 20px;
            bottom: 60px;
        }

        &__title {
            margin-left: 0;
        }
    }

    .a-voir {
        &__header {
            margin-left: -70px;
        }

        &__title {
            margin-left: 50px;
        }

        &__blocs {
            margin-top: 35px;
            gap: 20px;
        }
    
        &__bloc {
            &:nth-child(2), &:nth-child(3) {
                .a-voir__image {
                    width: 180px;
                    height: 155px;
    
                    &:before {
                        width: 180px;
                        height: 155px;
                    }
                }
            }
    
            a {
                max-height: 155px;
            }
        }
    
        &__image {
            width: 250px;
            height: 155px;
            
            figcaption {
                width: 140px;
            }

            &:before {
                width: 250px;
                height: 155px;
            }
        }
    }

    .admin-blocs .bloc .popup-area {
        .close-area-popup {
            right: calc(50% - 470px);
        }

        .container-popup-area iframe {
            max-width: 850px;
            max-height: 490px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .front-page {
        &__zones {
            &:before {
                height: 980px;
                bottom: 0;
                z-index: -1;
            }

            &--container {
                flex-direction: column;
                gap: 20px;
                padding-bottom: 70px;
            }
        }
    }

    .home-slideshow {
        .slideshow {
            width: 100vw;
            position: relative;

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }

            &__navigation {
                left: calc(50% - 280px);

                &__btn {
                    bottom: 25px;
                }
            }

            &__content {
                left: calc(50% - 310px);
                width: 595px;
            }
        }
    }

    .access {
        margin-top: 150px;

        &__container {
            position: relative;

            &:before, &:after {
                position: absolute;
                content: "";
                right: 10px;
                height: 10px;
                background-color: $color-main;
            }

            &:before {
                width: 105px;
                top: 0;
            }

            &:after {
                width: 210px;
                bottom: 0;
            }
        }

        &__list {
            width: 100%;
            gap: 45px 70px;
            padding: 45px 10px 50px 75px;
            border-left: none;
            border-right: 10px solid $color-main;

            &:after {
                bottom: unset;
                width: 10px;
                height: 160px;
                top: 0;
            }
        }
    }

    .home-events {
        margin-top: 90px;

        &:before {
            height: 530px;
        }

        &__swiper {
            margin-top: 40px;
        }

        &__list {
            margin-bottom: 140px;
        }

        &__button {
            bottom: 50px;
            top: unset;
            z-index: 1;
        }
    }

    .news {
        margin-top: 80px;

        &__swiper {
            padding-bottom: 135px;
        }

        &__list {
            margin-top: 40px;
        }

        &__link {
            &:hover, &:focus {
                .news__content {
                    height: 210px;
                }
            }
        }

        &__content {
            height: 90px;
        }

        &__button {
            bottom: 0;
            top: unset;
            z-index: 1;
        }
    }

    .social-wall {

        &:before {
            height: 870px;
        }

        &__container {
            padding: 100px 15px 55px;

            &:before, &:after {
                top: 248px;
                right: 70px;
            }
        }

        &__header {
            flex-direction: column;
            align-items: unset;
            gap: 30px;

            &__right {
                position: relative;
                left: 135px;
                width: fit-content;
            }
        }

        &__wrapper {
            margin-top: 30px;
        }

        #ff-stream-1 {
            min-height: 950px!important;

            .ff-item-meta {
                margin-top: -10px;

            }
        }
    }

    .home-documents {
        margin-top: 80px;

        &__container {
            padding-bottom: 90px;
        }

        &__list {
            margin-top: 40px;
        }

        &__item:last-child {
            display: none;
        }

        &__button {
            top: unset;
            bottom: 0;
        }
    }

    .home-newsletter {
        margin: 70px 0 80px;

        &__container {
            &:after {
                width: 75px;
            }
        }

        &__wrapper {
            flex-direction: column;
            align-items: flex-start;
            padding: 30px 0 40px;

            > p {
                padding: 5px 0 25px;
                margin-left: 85px;
                max-width: 440px;
            }

            > a {
                align-self: end;
                margin-right: 90px;
                margin-left: 0;
            }
        }
    }

    .zoom {
        margin-left: 0;
        display: flex;
        justify-content: center;

        &__header {
            > svg {
                margin-left: 0;
            }
        }
    }

    .a-voir {
        &__header {
            margin-left: 0;
        }
    
        &__bloc {
            &:nth-child(2), &:nth-child(3) {
                .a-voir__image {
                    width: 250px;
                    height: 215px;
    
                    &:before {
                        width: 250px;
                        height: 215px;
                    }
                }
            }
    
            a {
                max-height: 215px;
            }
        }
    
        &__image {
            width: 350px;
            height: 215px;
            
            figcaption {
                width: 205px;
            }

            &:before {
                width: 350px;
                height: 215px;
            }
        }
    }

    .social {
        position: absolute;
        top: 50px;
        transform: translate(0);

        a {
            padding: 0 15px 0 10px;

            &:not(:first-child) {
                display: none;
            }

            &:hover, &:focus {
                transform: translate(0);
            }
        }
    }

    .admin-blocs .bloc .popup-area {
        .close-area-popup {
            top: 40px;
            right: calc(50% - 310px);
        }

        .container-popup-area iframe {
            max-width: 530px;
            max-height: 300px;
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .front-page {
        &__title {
            margin-left: 0;
            text-align: center;
        }

        &__description {
            flex-direction: column;
            align-items: center;
            gap: 0;

            svg {
                margin-top: 13px;
            }
        }

        &__button {
            left: 50%;
            transform: translateX(-50%);
        }

        &__zones {
            &--container {
                padding-bottom: 60px;
                gap: 50px;
            }

            &:before {
                height: 1125px;
            }
        }
    }

    .home-slideshow {
        .slideshow {
            height: 315px;

            &__navigation {
                left: calc(50% - 120px);
            }

            &__content {
                left: calc(50% - 150px);
                top: 315px;
                width: 300px;
            }
        }
    }

    .access {
        margin-top: 175px;

        &__list {
            gap: 45px 30px;
            padding: 45px 10px 50px 35px;

            &:before {
                width: 60px;
            }

            &:after {
                height: 375px;
            }
        }
    }

    .home-events {
        margin-top: 75px;

        &__header {
            > div {
                flex-direction: column;
                align-items: center;
                gap: 20px;
            }
        }

        &__navigation {
            position: relative;
            left: calc(50% - 40px);
            height: 40px;
            width: 300px;
        }

        &__swiper {
            margin-top: 20px;
        }

        &__list {
            margin-bottom: 150px;
        }

        &__link {
            &:hover, &:focus {
                .home-events__content {
                    margin-left: 0;
                }
            }
        }

        &__image {
            left: 50%;
            transform: translateX(-50%);
        }

        &__content {
            left: 50%;
            transform: translateX(-50%)
        }

        &__button {
            bottom: 60px;
        }
    }

    .news {
        margin-top: 85px;

        &__header {
            > div {
                flex-direction: column;
                align-items: center;
                gap: 20px;
            }
        }

        &__navigation {
            position: relative;
            left: calc(50% - 40px);
            height: 40px;
            width: 300px;
        }

        &__swiper {
            margin-top: 20px;
        }
    }

    .social-wall {
        &:before {
            display: none;
        }

        &__container {
            padding: 100px 15px 0;
        }

        &__container {
            &:before, &:after {
                display: none;
            }
        }

        &__description {
            text-align: center;
        }

        &__header__right {
            left: 0;
        }

        &__wrapper {
            margin-top: 20px;
        }

        #ff-stream-1 {
            min-height: 1900px!important;
        }
    }

    .home-documents {
        margin-top: 75px;

        &__list {
            flex-direction: column;
            margin-top: 20px;
            gap: 20px;
        }
    }

    .home-newsletter {
        &__wrapper {
            padding: 30px 0 65px;

            > div {
                width: 280px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            > p {
                max-width: 200px;
                margin: 0 auto;
                text-align: center;
            }
        }

        &__header {
            flex-direction: column-reverse;
            align-items: center;
        }

        &__description {
            margin-left: 0;
        }

        &__content {
            gap: 10px;
        }
    }

    .zoom {
        &__image {
            > svg {
                width: 528px;
                height: 508px;
            }
        }

        &__header {
            flex-direction: column-reverse;
            align-items: center;
            gap: 0;

            > svg {
                margin-right: 10px;
            }
        }

        &__description {
            width: 300px;
        }

        &__content {
            gap: 10px;
        }

        .bloc__wrapper--video::before {
            top: 200px!important;
            transform: translateX(-50%)!important;
        }
    }

    .a-voir {
        &__description {
            text-align: center;
        }

        &__blocs {
            margin-top: 20px;
        }

        &__bloc {
            &:nth-child(2), &:nth-child(3) {
                .a-voir__image {
                    width: 140px;
                    height: 120px;
    
                    &:before {
                        width: 140px;
                        height: 120px;
                    }
                }
            }
        }
    
        &__image {
            width: 300px;
            height: 184px;
            
            figcaption {
                width: 120px;
                font-size: 13px;
            }

            &:before {
                width: 300px;
                height: 184px;
            }
        }
    }

    .social {
        top: 15px;
    }

    .admin-blocs {
        .bloc .popup-area {
            .close-area-popup {
                right: calc(50% - 150px);
            }

            .container-popup-area iframe {
                max-width: 280px;
                max-height: 280px;
            }
        }
    }
}
