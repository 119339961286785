.footer {
    margin: 75px auto 80px;

    &__container {
        position: relative;

        &:before, &:after {
            position: absolute;
            content: "";
            width: 65px;
            height: 10px;
            background-color: $color-main;
            left: 15px;
        }

        &:before {
            top: 0;
        }

        &:after {
            bottom: 0;
        }
    }

    &__border {
        border-left: 10px solid $color-main;
        padding: 60px 0 0 60px;
        position: relative;

        &:before, &:after {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            background-color: $color-main;
            z-index: -1;
        }

        &:before {
            width: 80px;
            height: 10px;
        }

        &:after {
            width: 10px;
            height: 205px;
        }
    }

    &__infos {
        display: flex;
        justify-content: space-between;
        margin-bottom: 80px;
    }

    &__title {
        font-family: $font-family;
        font-weight: $font-weight-bold;
        font-size: 22px;
        line-height: 24px;
        color: $color-dark;
    }

    &__text {
        font-family: $font-family;
        font-weight: $font-weight-normal;
        font-size: 16px;
        line-height: 22px;
        color: $color-dark;
        margin-top: 15px;
    }

    &__contact {
        width: 200px!important;
        margin-top: 20px;

        &:hover {
            background-color: $color-third;
            color: $color-dark;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding-right: 125px;
    }

    &__labels {
        display: flex;
        align-items: center;
        gap: 7px;
        font-family: $font-family;
        font-weight: $font-weight-normal;
        font-size: 16px;
        line-height: 18px;
        color: $color-dark;
        text-transform: uppercase;
        position: relative;

        &:before {
            position: absolute;
            content: " ";
            top: 0;
            left: -25px;
            width: 50px;
            height: 50px;
            background-color: $color-light;
            border-radius: 50px;
            z-index: -1;
            transition: all $duration ease-in-out;
        }

        &:hover {
            &:before {
                left: -6px;
            }

            span {
                color: $color-second;
            }
        }
    }

    &__menu {
        display: flex;
        justify-content: space-between;
        transform: translateY(22px);
        align-items: center;

        ul {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            flex-wrap: wrap;
        }

        li {
            position: relative;
            padding: 0 10px;
            font-family: $font-family;
            font-weight: $font-weight-normal;
            font-size: 14px;
            line-height: 20px;

            a {
                &:hover {
                        color: $color-main;
                }
            } 

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 12px;
                background: $color-text;
                transform: translateY(-50%);
            }

            &:last-child::before {
                background :transparent;
            }
        }
    }

    &__logos {
        > img {
            margin-right: 20px;
        }

        > a:not(:last-child) {
            margin-right: 20px;
        }

        a {
            height: fit-content;
            display: inline-flex;
            transition: all $duration ease-in-out;

            &:hover {
                transform: translateY(-5px);
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {

    .footer {
        margin: 65px auto;
        
        &__buttons {
            padding-right: 60px;
        }

        &__horaires {
            max-width: 240px;
        }

        &__menu {
            flex-direction: column-reverse;
            align-items: flex-end;
            gap: 35px;
            transform: translateY(3px);

            li {
                padding: 0 18px;
            }
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .footer {
        margin: 75px auto 140px;

        &__infos {
            flex-wrap: wrap;
            margin-bottom: -40px;
        }

        &__horaires {
            padding-right: 55px;
            max-width: 275px;
        }

        &__buttons {
            flex-direction: row;
            gap: 80px;
            margin-top: 85px;
            margin-left: 25px;
            padding-right: 0;
        }

        &__menu {
            gap: 40px;
            transform: translateY(118px);

            ul {
                justify-content: flex-end;
                gap: 15px 0;
            }

            li:last-child {
                padding-right: 0;
            }
        }
    }

}

// 640
@media screen and (max-width: $small) {

    .footer {
        &__container {
            &::before, &::after {
                display: none;
            }
        }

        &__border {
            padding: 0;
            border: none;
            
            &::before, &::after {
                display: none;
            }
        }

        &__angle {
            &--left {
                position: relative;

                &:before, &:after {
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 0;
                    background-color: $color-main;
                }

                &:before {
                    width: 80px;
                    height: 10px;
                }

                &:after {
                    width: 10px;
                    height: 100px;
                }
            }

            &--right {
                position: relative;

                &:before, &:after {
                    position: absolute;
                    content: "";
                    top: 0;
                    right: 0;
                    background-color: $color-main;
                }

                &:before {
                    width: 80px;
                    height: 10px;
                }

                &:after {
                    width: 10px;
                    height: 100px;
                }
            }

            &--bottom {
                position: relative;

                &:before, &:after {
                    position: absolute;
                    content: "";
                    bottom: 0;
                    left: 0;
                    background-color: $color-main;
                }

                &:before {
                    width: 65px;
                    height: 10px;
                }

                &:after {
                    width: 10px;
                    height: 100px;
                }
            }
        }

        &__infos {
            justify-content: center;
            padding: 55px 0 65px;
            position: relative;

            &:before, &:after {
                position: absolute;
                content: "";
                bottom: 0;
                right: 0;
                background-color: $color-main;
            }

            &:before {
                width: 65px;
                height: 10px;
            }

            &:after {
                width: 10px;
                height: 100px;
            }
        }

        &__coordonnees {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__horaires {
            margin-top: 115px;
            max-width: unset;
            padding-right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__text {
            text-align: center;
            max-width: 240px;
        }

        &__contact {
            margin-top: 125px;
        }

        &__buttons {
            margin-top: 35px;
            margin-left: 0;
            flex-direction: column;
            align-items: center;
            gap: 30px;
        }

        &__labels {
            flex-direction: column;

            &:before {
                left: calc(50% + -48px);
            }

            &:hover {
                &:before {
                    left: calc(50% + -48px);
                }
            }
        }

        &__logos {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            > img {
                margin: 0 50px 20px 50px;
            }

            > a:not(:last-child) {
                margin-right: 35px;
            }
        }

        &__menu ul {
            justify-content: center;
        }
    }

}