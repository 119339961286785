.cover {
    display: flex;

    &__image-wrapper {
        left: calc(50% + -960px);
        position: relative;
        min-width: 1065px;
        max-width: 1065px;

        img {
            object-fit: cover;
            object-position: top;
            height: 475px;
            width: 100%;
        }
    }

    &__informations {
        height: fit-content;
        display: flex;
        flex-direction: column;
        margin-top: 70px;
        padding: 60px 0 60px 80px;
        margin-left: -425px;
        border-left: 10px solid $color-main;
        position: relative;
        

        &:before, &:after {
            position: absolute;
            content: "";
            left: 0;
            height: 10px;
            background-color: $color-main;
        }

        &:before {
            top: 0;
            width: 100px;
        }

        &:after {
            bottom: 0;
            width: 210px;
        }
    }

    &__title {
        margin: 0;
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-family: $font-family;
            font-weight: $font-weight-normal;
            font-size: 18px;
            line-height: 24px;
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__image-wrapper {
            left: calc(50% + -600px);
            min-width: 690px;
            max-width: 690px;
    
            img {
                height: 400px;
            }
        }

        &__informations {
            margin-top: 80px;
            padding: 20px 0 25px 50px;
            margin-left: -165px;
        }

        &__title {
            font-size: 35px;
            line-height: 40px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .cover {
        display: flex;
        flex-direction: column;
        max-width: unset;
        padding: 0;
        align-items: center;

        &__image-wrapper {
            left: 0;
            min-width: unset;
            max-width: unset;
            width: 100vw;
        }

        &__informations {
            width: 620px;
            margin: 20px auto 35px;
            padding: 40px 0 45px 30px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__image-wrapper {
            img {
                height: 315px;
            }
        }

        &__informations {
            width: 300px;
            padding: 20px 0 25px 20px;
        }
    }

}
