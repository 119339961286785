.menu-fixe {
    display: none;
}

//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {
    .menu-fixe {
        display: flex;
        &__container {
            margin: 0 auto;
            width: 100%;
            display: flex;
            align-items: center;
        }
    }


    // NAV MENU
    .nav-menu {
        &__container {
            z-index: 1;
            min-width: 100%;
            min-height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            height: 0;
            visibility: hidden;
            opacity: 0;
            text-align: center;
            padding-top: 20px;
            z-index: -1;
            transition: all 0.3s ease-in-out;

            &.overlay {
                visibility: visible;
                opacity: 1;
                padding-top: 70px;
                background: $color-second;
                z-index: 4;
            }
            
        }
    
        &__icon {
            cursor: pointer;
            width: 100%;
            height: 100%;
            margin: 0;
            margin-left: 0;
            background-color: transparent;
            transition: all $duration ease-out;
    
            &--container {
                position: relative;
                display: flex;
                justify-content: center;
                flex-direction: row-reverse;
                align-items: center;
                background-color: transparent;
                color: $color-dark;
                width: fit-content;
                height: 30px;
                gap: 7px;
                font-family: $font-family;
                font-weight: $font-weight-normal;
                font-size: 22px;
                line-height: 24px;
                text-transform: uppercase;
                transition: all $duration ease-out;
                cursor: pointer;
                overflow: visible;
                margin-top: -5px;
            }

            &__close {
                visibility: hidden;
                opacity: 0;
                font-family: $font-family;
                font-weight: $font-weight-normal;
                font-size: 20px;
                line-height: 20px;
                color: $color-white;
                text-transform: uppercase;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 10px;
                position: absolute;
                top: 25px;
                left: calc(50% + 205px);
                z-index: 15;

                &.visible {
                    visibility: visible;
                    opacity: 1;
                }
            }

            &__back {
                visibility: hidden;
                opacity: 0;
                cursor: pointer;
                position: absolute;
                top: 25px;
                left: calc(50% + -300px);
                height: 30px;
                display: flex;
                align-items: center;
                z-index: 15;

                svg path {
                    fill: $color-white;
                }

                &.visible {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        &__list {
            max-width: 620px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 30px;
            height: 75vh;

            > a {
                display: none;
            }
        }

        &__item {
            font-family: $font-family;
            font-weight: $font-weight-bold;
            font-size: 30px;
            line-height: 30px;
            letter-spacing: 0.025em;
            text-transform: capitalize;
            color: $color-white;
            cursor: pointer;
            display: flex;
            height: 60px;

            & .nav-menu__sub {
                left: 100vw;
            }

            &.active {
                .nav-menu__sub {
                    left: 0;
                }
            }
        }

        &__sub {
            position: absolute;
            display: flex;
            width: 620px;
            background: $color-second;
            height: 100vh;
            padding-top: 40px;
            top: 0;
            width: 100vw;
            z-index: 5;
            left: 0;
            transition: all $duration ease-in-out;

            .nav-drop {
                margin: 50px 0 0;
                display: flex;
                flex-direction: column;
                gap: unset;
                height: 70vh;
                max-width: 300px;
                align-items: center;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                justify-content: space-around;

                p {
                    font-family: 'Kaushan Script', cursive;
                    font-weight: $font-weight-normal;
                    font-size: 30px;
                    line-height: 30px;
                    letter-spacing: 0.025em;
                    color: $color-white;
                    text-transform: capitalize;
                }

                li {
                    font-family: $font-family;
                    font-weight: $font-weight-light;
                    font-size: 18px;
                    line-height: 20px;
                    color: $color-white;
                    text-transform: none;
                    text-align: center;
                }
            }
        }

        &__link {
            width: 280px;
            margin: 0 auto;
            text-align: center;
            
            &:hover, &:active, &:focus {
                opacity: 0.7;
                color: $color-white;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .menu-fixe {
        &__container {
            .container {
                min-width: 300px;
                max-width: 300px;
            }
        }

        &__left {
            gap: 40px;
        }
    }

    .nav-menu {
        &__icon {
            &__back {
                left: calc(50% - 140px);
            }

            &__close {
                left: calc(50% + 30px);
            }

            &--container {
                margin-left: 55px;
                transform: translateY(-5px);
            }
        }

        &__list {
            > a {
                display: flex;

                &:hover {
                    color: $color-white;
                    opacity: .7;
                }
            }
        }
    }
}
